import Icon from './Icon';

const CHECK_ICON = (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.55685 15.91L4.82901 12.1848C4.41003 11.7661 3.73321 11.7661 3.31423 12.1848C2.89526 12.6035 2.89526 13.2798 3.31423 13.6985L7.80483 18.186C8.22381 18.6047 8.90063 18.6047 9.31961 18.186L20.6858 6.82773C21.1047 6.40905 21.1047 5.7327 20.6858 5.31402C20.2668 4.89533 19.59 4.89533 19.171 5.31402L8.55685 15.91Z" />
  </svg>
);

export default function IcCheck01({className}) {
  return (
    <Icon className={className} label="Check">
      {CHECK_ICON}
    </Icon>
  );
}

IcCheck01.propTypes = {
  className: Icon.propTypes.className,
};

IcCheck01.defaultProps = {
  className: '',
};
